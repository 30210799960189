<template>
    <div>
        <b-row class="criterias">
            <b-col md="3" v-if="hasOpens && !displaySortOptionsAsSelect">
                <checkbox-form-field caption="Résultats fédéraux uniquement" switch size="sm" v-model="excludeOpens" />
            </b-col>        
            <b-col md="9">
                <form-field caption='Tri :' v-if="displaySortOptionsAsSelect || (!canSortAlphabeticaly && !canSortBySubscriptionOrder)">
                    <b-form-select v-model="sortType" size="sxm" >
                        <b-select-option-group label="Classements">
                            <b-select-option value="rank"><b>Classement Général</b></b-select-option>
                            <b-select-option value="precision_rank">"Meilleure précision"</b-select-option>
                            <b-select-option value="ballet_rank">"Meilleur ballet"</b-select-option>
                        </b-select-option-group >
                        <b-select-option-group label="Autres tris" v-if="canSortAlphabeticaly || canSortBySubscriptionOrder">
                            <b-select-option v-if="canSortAlphabeticaly" value="alphabetical">Alphabétique</b-select-option>
                            <b-select-option v-if="canSortAlphabeticaly" value="subcription">Ordre d'inscription</b-select-option>
                        </b-select-option-group>
                    </b-form-select>
                </form-field>
                <b-radio-group v-model="sortType" size="xsm" stacked v-else>
                    <b-form-radio value="rank"><b>Classement Général</b></b-form-radio>
                    <b-form-radio value="precision_rank">Classement "Meilleure précision"</b-form-radio>
                    <b-form-radio value="ballet_rank">Classement "Meilleur ballet"</b-form-radio>
                </b-radio-group>
            </b-col>
            <b-col md="3" v-if="hasOpens && displaySortOptionsAsSelect">
                <checkbox-form-field caption="Résultats fédéraux uniquement" switch size="sm" v-model="excludeOpens" />
            </b-col> 
        </b-row>
        <div>
            <slot :excludeOpens="excludeOpens" :sortType="sortType" ></slot>
        </div>
    </div>
</template>

<script>
import FormField from '@/components/Panels/FormField.vue';
import CheckboxFormField from '../../../components/Panels/CheckboxFormField.vue';
export default {
    components:{ CheckboxFormField, FormField },
    props:{
        hasOpens:{ type: Boolean, default: false, },
        canSortAlphabeticaly: { type: Boolean, default: false},
        canSortBySubscriptionOrder: { type: Boolean, default: false},
        displaySortOptionsAsSelect: { type: Boolean, default: false},
    },
    data(){
        return {
            excludeOpens: false,
            sortType: 'rank',
        };
    },
/*    computed:{
        excludeOpens: {
            get(){ return this.criteriasAndOptions.excludeOpens; },
            set(newValue){ this.$emit('change', { ...this.criteriasAndOptions, excludeOpens: newValue}); }
        },
        with_ranks: {
            get(){ return this.criteriasAndOptions.with_ranks; },
            set(newValue){ return { ...this.criteriasAndOptions, with_ranks: newValue}; }
        },
        with_subtotals: {
            get(){ return this.criteriasAndOptions.with_subtotals; },
            set(newValue){ return { ...this.criteriasAndOptions, with_subtotals: newValue}; }
        },    
        sortType: {
            get(){ return this.criteriasAndOptions.sortType; },
            set(newValue){ return { ...this.criteriasAndOptions, sortType:newValue}; }
        }
    }*/
}
</script>

<style scoped>
    .criterias{ background-color: gainsboro; margin: 10px; padding: 5px; border-radius:5px; }
</style>