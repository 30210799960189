<template>
    <div v-if="dCompetition != null">
        <b-button v-if="userCanPublish && displayMode != 'publish'" @click="displayMode='publish'" style="float:right;">Basculer l'affichage pour publication</b-button>
        <b-button v-if="userCanPublish && displayMode == 'publish'" @click="displayMode='standard'" style="float:right;" >Retour à l'affichage standard</b-button>
        <h4>{{dCompetition.name}}</h4>
        <h5>Résultats{{ dCompetition.isCompleted ? "" : " temporaires " }}</h5>
        <collapse-panel title="Généralités" :visible="generalPaneVisible" @expand="generalPaneVisible=true" @collapse="generalPaneVisible=false">
            <div class="container" v-if="generalPaneVisible">
                <div class="property">
                    <div class="label">Type :</div>
                    <div class="value">{{dCompetition.COMPETITION_TYPE.name}}</div>
                </div>
                <div class="property">
                    <div class="label">Echelon :</div>
                    <div class="value">{{dCompetition.SCOPE.name}}</div>
                </div>
                <div class="property">
                    <div class="label">Compétition fédérale :</div>
                    <div class="value">{{getOuiNon(dCompetition.REGULATION.isFederal)}}</div>
                </div>
                <div class="property">
                    <div v-if="dCompetition.REGULATION.isFederal" class="label">Mode "Open" :</div>
                    <div v-if="dCompetition.REGULATION.isFederal" class="value">{{getOuiNon(dCompetition.isOpen)}}</div>
                </div>
                <div class="property">
                    <div class="label">Règlement :</div>
                    <div class="value">{{dCompetition.REGULATION.name}}</div>
                </div>
                <div class="property">
                    <div class="label">Lieu :</div>
                    <div class="value">{{dCompetition.location}}</div>
                </div>
                <div class="property">
                    <div class="label">Chef(s) juge(s) :</div>
                    <div class="value">{{dCompetition.STAFF_MEMBERS.filter(s => s.isChiefJudge).map(s => s.firstname + ' ' + s.lastname).join(', ')}}</div>
                </div>
                <div class="property">
                    <div class="label">Réprésentant(s) des pilotes</div>
                    <div class="value">{{dCompetition.DELEGATES.map(d => d.firstname + ' ' + d.lastname).join(', ')}}</div>
                </div>
                <div class="property">
                    <div class="label">Règle de détermination du score final :</div>
                    <div class="value">{{dCompetition.RESULT_CALCULATION_MODE.name}} <info-helper v-if="dCompetition.RESULT_CALCULATION_MODE.description != null && dCompetition.RESULT_CALCULATION_MODE.description != ''">{{dCompetition.RESULT_CALCULATION_MODE.description}}</info-helper></div>
                </div>
                <div class="property">
                    <div class="label">Staff :</div>
                    <div class="value">{{staff}}.</div>
                </div>
            </div>
        </collapse-panel>

        <tab-if v-model="dCompetition.LEVELS" :itemKey="(item)=>item.uniqueKey" :itemText="(item)=>item.name" v-slot="level_data">
            <h5 v-if="level_data.hasItem && !level_data.isSingleItem">{{level_data.item.name}}</h5>
            <collapse-panel-if v-model="level_data.item.CATEGORIES" :itemKey="(item)=>item.uniqueKey" :itemText="(item)=>item.name" v-slot="category_data" :visible="false">
                <div v-if="true">
                    <h5>Classement final {{ dCompetition.isCompleted ? '' : '(temporaire)'}}</h5>
                    <final-results-criterias-and-options v-slot="criteriasProps" :hasOpens="dCompetition.isOpen == 1" displaySortOptionsAsSelect>
                        <final-results-table :Results="category_data.item.COMPETITORS" :displayMode="displayMode" :excludeOpens="criteriasProps.excludeOpens" :sortType="criteriasProps.sortType">
                            <template #publishModeTitle><p class="table-row" style="font-size:12pt; font-weight:bold; color: #5898D5;">{{category_data.item.longname}}</p></template>
                        </final-results-table>
                    </final-results-criterias-and-options>
                    <h5>Résultats détaillés par épreuve</h5>
                </div>
                <div v-else>
                    Aucune épreuve n'a encore été commencée pour cette catégorie.
                </div>
                <b-container>
                    <collapse-panel-if v-model="category_data.item.EVENTS" :itemKey="(item)=>item.id" :itemText="(item)=>item.name + ' - ' + item.round_number" v-slot="event_data">
                        <div v-if="event_data.item.isCompleted==1">{{event_data.isSingleItem ? "L'unique épreuve (" + event_data.item.name + ") de la compétition est terminée pour cette catégorie." : 'Cette épreuve est terminée.'}}</div>
                        <div v-else-if="event_data.item.isStarted==1">{{event_data.isSingleItem ? "L'unique épreuve (" + event_data.item.name + ") se déroule en ce moment même." : 'Cette épreuve se déroule en ce moment même.'}}'</div>
                        <div v-if="event_data.item.hasCompulsories" style="display:flex; justify-content: space-around;">
                            <span style="align-self: flex-start !important;">Les figures de précisions retenues sont : </span>
                            <span v-for="compulsory in event_data.item.COMPULSORIES" :key="compulsory.id" class="compulsory-ref">{{compulsory.code}} - {{compulsory.name}}</span>
                        </div>
                        <div v-if="event_data.item.isStarted==1" style="margin-bottom:10px;">
                            <event-results v-model="event_data.item" :displayMode="displayMode" :competitors="category_data.item.COMPETITORS" :isOptionAndSortPanelVisible="false"></event-results>
                        </div>
                        <div v-else>Cette épreuve n'est pas encore commencée.</div>
                        <div v-if="dCompetition.isCompleted && dCompetition.national_ranking != null">
                            <h5>Classement national provisoire à l'issu de cette manche</h5>
                            <championship-ranking :Results="dCompetition.national_ranking.filter(ri => ri.category == category_data.item.code && ri.level == level_data.item.code )">

                            </championship-ranking>
                        </div>
                    </collapse-panel-if>
                </b-container>
            </collapse-panel-if>
        </tab-if>

        <collapse-panel title="Classement national provisoire à l'issu de cette compétition" v-if="displayMode == 'publish'">
            <championship-ranking style="margin-top:10px; margin-bottom: 10px;" :displayMode="displayMode" :Results="dCompetition.national_ranking"></championship-ranking>
        </collapse-panel>
    </div>
    <div v-else>
        Aucune compétition spéficiée !
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import CollapsePanel from '../components/Panels/CollapsePanel.vue';
import InfoHelper from '../components/InfoHelper.vue';
import TabIf from '../components/Panels/TabIf.vue';
import CollapsePanelIf from '../components/Panels/CollapsePanelIf.vue';
import FinalResultsCriteriasAndOptions from '@/views/Competitions/components/FinalResultsCriteriasAndOptions.vue';
//import FinalResultsTable from '@/views/Competitions/components/FinalResultsTable.vue';
import FinalResultsTable from '../components/CompetitionResults/FinalResultsTable.vue';
import EventResults from "../components/CompetitionResults/EventResults.vue";
import ChampionshipRanking from '../components/CompetitionResults/ChampionshipRanking.vue';

export default{
  components: { CollapsePanel, InfoHelper, TabIf, CollapsePanelIf, FinalResultsCriteriasAndOptions, FinalResultsTable, EventResults, ChampionshipRanking },
    data(){
        return {
            dCompetition: null,
            generalPaneVisible: false,
            displayMode: 'standard',
            userCanPublish: false,
        }
    },
    computed:{
        ...mapGetters('current', ['ViewedCompetitionId']),
        staff(){
            var ret = this.dCompetition.STAFF_MEMBERS.map(d => d.firstname + ' ' + d.lastname);
            ret = ret.sort((a,b) => a.toLowerCase().localeCompare(b.toLowerCase()));
            return ret.join(', ');
        }
    },
    methods:{
        getOuiNon(boolValue){
            return boolValue ? "Oui" : "Non";
        },
        async refresh(){
            await this.showLoader();
            this.userCanPublish = await this.userCan('VIEW_COMPETITION_RESULTS_FOR_PUBLICATION');
            var url = this.getUrl('api/CompetitionResults/getCompetitionResults.php');
            try{
                const response = await axios.post(url, { id: this.ViewedCompetitionId } );
                //console.log('response', response);
                //console.log('compet', response.data.competition);
                this.dCompetition = response.data.competition;
                console.log(this.dCompetition);
            }
            catch(error){
                console.log('refresh error=>', error);
            }
            this.hideLoader();
        },
    },
    async mounted(){
        await this.refresh();
    }
}
</script>

<style scoped>
    .property .value { font-weight: bolder;}
    .property .label { color:#c0c0c0; }

    .compulsory-ref { border: solid 1px #707070; margin:10px; margin-right: 20px; padding: 2px;}
</style>