<template>
    <div>
        <div ref="render" class="results-render final-result" style="display:flex">
            <table v-if="displayMode=='standard'" style="font-size: 0.8rem;">
                <thead>
                    <tr class="grid-header">
                        <!--<th class="trophy" rowspan="2"></th>-->
                        <th rowspan="2" colspan="2">Compétiteur</th>
                        <th rowspan="2">Moyenne<br/>générale</th>
                        <th rowspan="2">Rg.</th>
                        <th v-if="showSubTotals || showSubRanks" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)" :class='{"N/A": !hasPrecision}'>Précision</th>
                        <th v-if="showSubTotals || showSubRanks" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)" :class='{"N/A": !hasBallet}'>Ballet</th>

                        <th colspan="2">Participations</th>
                    </tr>
                    <tr class="grid-header">
                        <th v-if="showSubTotals" :class='{"N/A": !hasPrecision}'>Moy.</th>
                        <th v-if="showSubRanks" :class='{"N/A":!hasPrecision}'>Rg.</th>
                        <th v-if="showSubTotals" :class='{"N/A": !hasBallet}'>Moy.</th>
                        <th v-if="showSubRanks" :class='{"N/A": !hasBallet}'>Rg.</th>
                        <th>Nb.</th>
                        <th>Dernière</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in rows" :key="result.competitor_id" :class="{'even': (result.rowIndex % 2 == 0), 'odd': (result.rowIndex %2 !=0)}">
<!--                        <td class="trophy" v-if="sortType=='precision_rank'">-->
                            <!-- Classement pour "Champion National de Précision" -->
<!--                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.precision_rank < 4" :variant="result.precision_rank == 1 ? 'gold' : (result.precision_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.precision_federal_rank < 4" :variant="result.precision_federal_rank == 1 ? 'gold' : (result.precision_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else-if="sortType=='ballet_rank'">-->
                            <!-- Classement pour "Champion National de Ballet" -->
<!--                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.ballet_rank < 4" :variant="result.ballet_rank == 1 ? 'gold' : (result.ballet_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.ballet_federal_rank < 4" :variant="result.ballet_federal_rank == 1 ? 'gold' : (result.ballet_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else>-->
                            <!-- Classement pour "Champion de France" -->
<!--                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.rank < 4" :variant="result.rank == 1 ? 'gold' : (result.rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.federal_rank < 4" :variant="result.federal_rank == 1 ? 'gold' : (result.federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                    -->
                        <td class="row-index">{{result.rowIndex}}</td>
                        <td class="name">{{result.name}}</td>
                        <td>{{ arrondir(result.avg_overall) }}</td>
                        <td><b>{{ result.rank }}</b></td>

                        <td v-if="(showSubTotals || showSubRanks) && result.avg_precision == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"></td>
                        
                        <td v-if="showSubTotals && result.avg_precision != null">{{ result.avg_precision == null ? 'N/A' : arrondir(result.avg_precision) }}</td>
                        <td v-if="showSubRanks && result.avg_precision !=null">{{ result.avg_precision == null ? '' : result.precision_rank }}</td>
                        
                        
                        <td v-if="(showSubTotals || showSubRanks) && result.avg_ballet == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"></td>
                        
                        <td v-if="showSubTotals && result.avg_ballet != null">{{ result.avg_ballet == null ? 'N/A' : arrondir(result.avg_ballet) }}</td>
                        <td v-if="showSubRanks && result.avg_ballet !=null">{{ result.avg_ballet == null ? '' : result.ballet_rank }}</td>


                        <td>{{ result.nb_participations}}</td>
                        <td>{{ result.last_participation_date}}</td>
                    </tr>
                </tbody>
            </table>
            <table v-else style="font-size: 9pt;" class="publish">
                <thead>
                    <tr class="grid-header">
                        <th style="width: 7.5cm;" rowspan="2" colspan="2"><p>Compétiteur</p></th>
                        <th colspan="2"><p>GENERAL</p></th>
                        <th :colspan="2" :class='{"N/A": !hasPrecision}'><p>Précision</p></th>
                        <th :colspan="2" :class='{"N/A": !hasBallet}'><p>Ballet</p></th>

                        <th colspan="2">Participations</th>
                    </tr>
                    <tr class="grid-header">
                        <th style="width:1.5cm"><p>Moy.</p></th>
                        <th style="width:0.95cm"><p>Rg.</p></th>
                        <th style="width:1.5cm" :class='{"N/A": !hasPrecision}'><p>Moy.</p></th>
                        <th style="width:0.95cm" :class='{"N/A":!hasPrecision}'><p>Rg.</p></th>
                        <th style="width:1.5cm" :class='{"N/A": !hasBallet}'><p>Moy.</p></th>
                        <th style="width:0.95cm" :class='{"N/A": !hasBallet}'><p>Rg.</p></th>
                        <th style="width:0.95cm"><p>Nb.</p></th>
                        <th style="width:1.5cm"><p>Dernière</p></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in rows" :key="result.key" :class="{'even': (result.rowType=='rank' && result.rowIndex % 2 == 0), 'odd': (result.rowType == 'rank' && result.rowIndex %2 !=0), 'level': result.rowType == 'level', 'category': result.rowType == 'category'}">
<!--                        <td class="trophy" v-if="sortType=='precision_rank'">-->
                            <!-- Classement pour "Champion National de Précision" -->
<!--                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.precision_rank < 4" :variant="result.precision_rank == 1 ? 'gold' : (result.precision_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.precision_federal_rank < 4" :variant="result.precision_federal_rank == 1 ? 'gold' : (result.precision_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else-if="sortType=='ballet_rank'">-->
                            <!-- Classement pour "Champion National de Ballet" -->
<!--                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.ballet_rank < 4" :variant="result.ballet_rank == 1 ? 'gold' : (result.ballet_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.ballet_federal_rank < 4" :variant="result.ballet_federal_rank == 1 ? 'gold' : (result.ballet_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else>-->
                            <!-- Classement pour "Champion de France" -->
<!--                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.rank < 4" :variant="result.rank == 1 ? 'gold' : (result.rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.federal_rank < 4" :variant="result.federal_rank == 1 ? 'gold' : (result.federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                    -->
                        <td v-if="result.rowType=='level'" colspan="10" style="background-color:#365F91; color:white;"><p style="font-weight: bold;">{{ result.level }}</p></td>
                        <td v-if="result.rowType=='category'" colspan="10" style="background-color:#548dd4; color:white;"><p style="font-weight: bold;">{{ result.category }}</p></td>
                        <td v-if="result.rowType=='rank'" class="row-index"><p>{{result.rowIndex}}</p></td>
                        <td v-if="result.rowType=='rank'" class="name"><p>{{result.name}}</p></td>
                        <td v-if="result.rowType=='rank'" ><p>{{ arrondir(result.avg_overall) }}</p></td>
                        <td v-if="result.rowType=='rank'"><p style="font-weight: bold;">{{ result.rank }}</p></td>

                        <td v-if="result.rowType=='rank' && (showSubTotals || showSubRanks) && result.avg_precision == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"><p></p></td>
                        
                        <td v-if="result.rowType=='rank' && showSubTotals && result.avg_precision != null"><p>{{ result.avg_precision == null ? 'N/A' : arrondir(result.avg_precision) }}</p></td>
                        <td v-if="result.rowType=='rank' &&showSubRanks && result.avg_precision !=null"><p>{{ result.avg_precision == null ? '' : result.precision_rank }}</p></td>
                        
                        
                        <td v-if="result.rowType=='rank' && (showSubTotals || showSubRanks) && result.avg_ballet == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"><p></p></td>
                        
                        <td v-if="result.rowType=='rank' && showSubTotals && result.avg_ballet != null"><p>{{ result.avg_ballet == null ? 'N/A' : arrondir(result.avg_ballet) }}</p></td>
                        <td v-if="result.rowType=='rank' && showSubRanks && result.avg_ballet !=null"><p>{{ result.avg_ballet == null ? '' : result.ballet_rank }}</p></td>


                        <td v-if="result.rowType=='rank'"><p>{{ result.nb_participations}}</p></td>
                        <td v-if="result.rowType=='rank'"><p>{{ result.last_participation_date}}</p></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default{
    props: {
        Results: { type: Array, required: true},
        includePrecisionResults: { type: Boolean, default: true},
        includeBalletResults: { type: Boolean, default: true},
        includeSubTotals: { type: Boolean, default: true},
        includeSubRanks: { type: Boolean, default: true},
        excludeOpens: { type: Boolean, default: false},
        sortType: { type: String, default: 'rank'},
        displayMode: {type:String, default: 'standard'},
    },
    computed:{
        hasPrecision(){
            return this.rows ? this.rows.findIndex(r => r.precision_note != null) >= 0 : false;
        },
        hasBallet(){
            return this.rows ? this.rows.findIndex(r => r.ballet_note != null) >= 0 : false;
        },
        showSubTotals(){
            return this.includeSubTotals;
        },
        showSubRanks(){
            return this.includeSubRanks;
        },
        rows(){
            var r = this.Results.map(ri => ri);
            if(this.displayMode == 'standard'){
                return this.prepareRows(r);
            }

            var ret = [];
            var levels = r.map(lvl => lvl.level)
                            .filter((v,i,a) => a.indexOf(v) == i)
                            .map(lvl => {
                                var f = r.filter(o => o.level == lvl)[0];
                                return {code: f.level, name: f.level_name, displayOrder: f.level_displayOrder};
                            });
            levels.sort((a,b) => a.displayOrder - b.displayOrder);
            levels.forEach(lvl => {
                if(levels.length > 1) ret.push({ level: lvl.name, rowType:"level", key:lvl.code });
                var categories = r.filter(cat => cat.level == lvl.code)
                                  .map(cat => cat.category)
                                  .filter((v,i,a) => a.indexOf(v) == i)
                                  .map(cat => { 
                                    var f = r.filter(o => o.category == cat)[0]; 
                                    return {code: f.category, name: f.category_name, displayOrder: f.category_displayOrder};
                                  });
                categories.sort((a,b) => a.displayOrder - b.displayOrder);
                categories.forEach(cat => {
                    if(categories.length > 1) ret.push({ level: lvl.code, category: cat.name, rowType:"category", key:lvl.code+cat.code});
                    var ranks = r.filter(rank => rank.level == lvl.code && rank.category == cat.code);
                    ranks = this.prepareRows(ranks);
                    ret = ret.concat(ranks);
                });
            });
            return ret;
        },

    },
    methods:{
        prepareRows(r){
            r.sort((a,b) => this.compareScores(a.avg_overall, b.avg_overall));
            var lastScore = 0;
            r = r.map((ri, i) => {
                    ri.rank = (ri.avg_overall == lastScore && i > 0) ? r[i-1].rank : i+1;
                    ri.exaequo_overall = true;
                    //ri[i-1].exaequo_overall = true;
                    lastScore = ri.avg_overall;
                    return ri;
                });

            r.sort((a,b) => this.compareScores(a.avg_precision, b.avg_precision));
            lastScore = 0;
            r = r.map((ri, i) => {
                    if(ri.avg_precision == null){
                        ri.prcision_rank = null;
                    }else {
                        ri.precision_rank = (ri.avg_precision == lastScore) ? r[i-1].precision_rank : i+1;
                        ri.exaequo_precision = true;
                        //ri[i-1].exaequo_precision = true;
                        lastScore = ri.avg_precision;
                    }
                    return ri;
                });

            r.sort((a,b)=> this.compareScores(a.avg_ballet, b.avg_ballet));
            lastScore = 0;
            r = r.map((ri, i) => {
                    if(ri.avg_ballet == null){
                        ri.ballet_rank = null;
                    }else {
                        ri.ballet_rank = (ri.avg_ballet == lastScore) ? r[i-1].ballet_rank : i+1;
                        ri.exaequo_ballet = true;
                        //ri[i-1].exaequo_ballet = true;
                        lastScore = ri.avg_ballet;
                    }
                    return ri;
                });

            switch(this.sortType){
                case "rank":
                    r.sort((a,b) => this.compareRanks(a.avg_overall, b.avg_overall, a.rank, b.rank));
                    break;
                case "precision_rank":
                    r.sort((a,b) => this.compareRanks(a.avg_precision, b.avg_precision, a.precision_rank, b.precision_rank));
                    break;
                case "ballet_rank":
                    r.sort((a,b) => this.compareRanks(a.avg_ballet, b.avg_ballet, a.ballet_rank, b.ballet_rank));
                    break;
                case "alphabetical":
                    r.sort((a,b) => a.name.localeCompare(b.name));
                    break;
            }
            return r.map((ri, i) => { ri.rowIndex = i+1; ri.key = ri.competitor_id; ri.rowType='rank'; return ri; });
        },
        compareScores(aScore, bScore){
            var ret = 0;
            if(aScore != null && bScore != null)
                ret = bScore - aScore;
            else if(aScore != null)
                ret = -1;
            else if(bScore != null)
                ret = 1
            return ret;
        },
        compareRanks(aNote, bNote, aRank, bRank){
            var ret = 0;
           if(aNote != null && bNote != null)
                ret = aRank-bRank;
            else if(aNote != null)
                ret = -1;
            else if(bNote != null)
                ret = 1;
            //console.log('compateRanks', ret, aNote, bNote, aRank, bRank);
             return ret;
        }
    }
}
</script>

<style >
    .results-render{
        display:flex;
        justify-content: center;
    }
    .results-render > table {
        border-collapse: collapse;
        border: solid 2px var(--ffvlOrangePicto);
    }
    .results-render > table  td, .results-render > table th{
        border: solid 1px var(--ffvlOrangePicto);
        text-align: center;
    }
    table.results { margin-top: 10px;}
    .results{ border-collapse: collapse !important; }
    .results > tbody > tr > th { font-size: 0.95rem !important; orientation: landscape; border:darkgrey 1px solid !important; padding-left: 3px; padding-right: 3px; background-color:var(--ffvlBleuLogo) !important;}
    table.results > tbody > tr > td { font-size: 0.75rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    .results > tbody > tr > th.total{ background-color: var(--resultTotalHeader);}
    .results > tbody > tr > td.total{ background-color: var(--resultTotal);}
    .results > tbody > tr > th.rank{ background-color: var(--resultRankHeader);}
    .results > tbody > tr > td.rank{ background-color: var(--resultRank);}
    .results > tbody > tr > th.total, .results > tbody > tr > td.total { font-weight: bolder; color: var(--primary);}
    .results > tbody > tr > th.sorted{ background-color: var(--sortedHeader);}
    .results > tbody > tr > td.sorted{ background-color: var(--sortedCell);}

      tr.even td { background-color: beige;}
    tr.odd td{ background-color: #cdcdfd;}

    .trophy {
        background-color: transparent !important;
        border-left: none !important;
        border-right: solid 2px var(--ffvlOrangePicto) !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-index{ padding-left: 7px; padding-right: 7px;}

    .name{ min-width:150px !important; padding-left: 2px; text-align: left !important;}
 
    .non-participant{
        background-color: #e0e0e0 !important;
        color: #808080;
        font-style: italic;
    }

    .results-render > table.publish  td{
        border:solid 1px black !important;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        .results-render{
            orientation: landscape;
        }
    }