<template>
    <b-tab v-if="value.length > 1">
        <tab v-for="item in value" :key="itemKey(item)" :title="itemText(item)">
            <slot :item="item" :isSingleItem="false" :hasItem="true"></slot>
        </tab>
    </b-tab>
    <div v-else-if="value.length == 0">
        <slot :item="null" :isSingleItem="false" :hasItem="false"></slot>
    </div>
    <div v-else>
        <slot :item="value[0]" :isSingleItem="true" :hasItem="true"></slot>
    </div>
</template>

<script>
export default{
    props:{ value: { type:Array, required:true}, 
            itemKey: { type:Function, required:true},
            itemText: { type:Function, required:true }
        }
}
</script>
