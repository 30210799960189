<template>
    <div v-if="value.length > 1">
        <collapse-panel v-for="item in value" :key="itemKey(item)" :title="itemText(item)" :visible="visible">
            <template #title><slot name="title" :item="item"></slot></template>
            <slot :item="item" :isSingleItem="false" :hasItem="true"></slot>
        </collapse-panel>
    </div>
    <div v-else-if="value.length == 0">
        <slot :item="null" :isSingleItem="false" :hasItem="false"></slot>
    </div>
    <div v-else>
        <slot :item="value[0]" :isSingleItem="true" :hasItem="true"></slot>
    </div>
</template>

<script>
import CollapsePanel from './CollapsePanel.vue'
export default{
  components: { CollapsePanel },
    props:{ value: { type:Array, required:true}, 
            itemKey: { type:Function, required:true},
            itemText: { type:Function, required:true },
            visible: { type: Boolean, default: true },
        }
}
</script>
